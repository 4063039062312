








































import { Turma, Usuario } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService, UsuarioService } from "@/core/services/geral";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ConfiguracaoComissao extends PageBase {
    
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    turmas: Turma[] = [];
    isTurmaLoading: boolean = false;

    gestores: Usuario[] = [];
    isGestorLoading: boolean = false;

    lista: any[] = [];
    selectLista: any[] = [];
    loading: boolean = false;
    headers: any[] = [
        { text: "", sortable: false },
        { text: "Nome", value: "nome" },
        { text: "Tipo", value: "tipo"}
    ];
    
    filtro: any = {
        turmaId: 0,
        gestorId: 0
    }

    $refs!: {
        form: HTMLFormElement
    }

    mounted() {
        new TurmaService().ListarAbertas().then(
            res=> {
                this.turmas = res.data.items
            }
        )

        new UsuarioService().ListarGestores().then(
            res => {
                this.gestores = res.data.items
            }
        )
    }

    GerarRelatorio(){
        
        if (this.$refs.form.validate()) {

            let routeLink = this.$router.resolve({name:"impressaoComissao",
                query:{
                    turmaId: this.filtro.turmaId,
                    gestorId: this.filtro.gestorId
                }
            });

            window.open(routeLink.href,'_blank');
            
        }
    }

    LimparFiltros(){
        this.filtro.turmaId = 0;
        this.filtro.gestorId = 0;
        this.$refs.form.resetValidation();
    }

}
